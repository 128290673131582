import { useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';

const pw = '8675309';
const nextPage = 'Dancers';

const Barrier = ({setPage}) => {
    const [password, setPassword] = useState('');

    const handleKeyPress = e => {
        if (e.key === 'Enter' && e.target.value === pw) setPage(nextPage);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, []);

    const checkPassword = () => {
        if (password === pw) setPage(nextPage);
    }

    return (
        <div style={{padding: 20, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <Form style={{width: 250, margin: '0 auto'}}>
                <Form.Field control={Input} type="password" value={password} label="Code" fluid onChange={e => setPassword(e.target.value)} />
                <Button primary onClick={checkPassword}>Go!</Button>
            </Form> 
        </div>
    );
}

export default Barrier;