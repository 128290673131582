import Axios from 'axios';
import apiString from './apiString';
import moment from 'moment';
import { eventDate1, eventDate2 } from './utilities';


const placePoints = {
    1: 137,
    2: 91,
    3: 71,
    4: 53,
    5: 37,
    6: 23
};

const competitionConverter = {
    'Open Championship': 'checkedinopen',
    'Irish Jig Challenge - 5 Steps': 'checkedinjig',
    'Scottish National Dance Premiership': 'checkedinscottish',
    'Pre-Premier Competition': 'checkedinprecomp',
    'Pre-Championship': 'checkedinprechamp',
};

const reverseDanceIdConverter = {
    1: 'Open Championship',
    2: 'Irish Jig Challenge - 5 Steps',
    3: 'Pre-Premier Competition',
    4: 'Pre-Championship',
    5: 'Scottish National Dance Premiership'
};

export const crunchResults = (dataMatrix, loadData, setLoading, tableData) => {
    setLoading(true);

    let insertString = 'INSERT INTO scrutineering (id, judgestotal, judgestotalfinal, totalpoints, place, scoreafinal, scorebfinal, scorecfinal) VALUES';

    for (let i = 0; i < Object.keys(dataMatrix).length; i++) {
        const comp = Object.keys(dataMatrix)[i];
        if (dataMatrix[comp].dances.length > 0) {
            const data = tableData.filter(item => item.competition === comp);
            insertString = crunchContest(data, insertString);
            continue;
        }
        for (let ii = 0; ii < Object.keys(dataMatrix[comp].bucketItems).length; ii++) {
            const bucketItem = Object.keys(dataMatrix[comp].bucketItems)[ii];
            if (dataMatrix[comp].bucketItems[bucketItem].dances.length > 0) {

                const data = tableData.filter(item => item.competition === comp && item.bucket === bucketItem);
                insertString = crunchContest(data, insertString);
                continue;
            }
            for (let iii = 0; iii < Object.keys(dataMatrix[comp].bucketItems[bucketItem].subbucketItems).length; iii++) {
                const subbucketItem = Object.keys(dataMatrix[comp].bucketItems[bucketItem].subbucketItems)[iii];
                const data = tableData.filter(item => item.competition === comp && item.bucket === bucketItem && item.subbucket === subbucketItem);
                insertString = crunchContest(data, insertString);
            }
        }
    }

    insertString += ' ON DUPLICATE KEY UPDATE judgestotal = VALUES(judgestotal), judgestotalfinal = VALUES(judgestotalfinal), totalpoints = VALUES(totalpoints), place = VALUES(place), scoreafinal = VALUES(scoreafinal), scorebfinal = VALUES(scorebfinal), scorecfinal = VALUES(scorecfinal)';
    if (insertString.split(',').length - 1 > 13) { //only do a db update if there are actually results to post
        const insertData = new FormData();
        insertData.set('action', 'insertResults');
        insertData.set('insertString', insertString);
        Axios.post(apiString, insertData).then(() => {
            loadData();
        });
    }
    else loadData();
}

const addScores = item => {
    let score = +item.scorea + +item.scoreb + +item.scorec;
    //if the score has more than 2 decimal places, round it to 2 decimal places.  it must end up being a number either way, not a string.  i have to write this because of javascript's weirdness with numbers and decimals.
    if (score.toString().split('.')[1] !== undefined && score.toString().split('.')[1].length > 2) score = Math.round(score * 100) / 100;
    return score;
}

const crunchContest = (data, insertString) => {
    const totals = {};
    //add up judges points
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (totals[item.dance] === undefined) totals[item.dance] = [];
        let newDance = {
            id: item.id,
            danceNumber: item.dancenumber,
            judgesPoints: addScores(item),
            totalPoints: 0,
            place: 0,
            scoreA: +item.scorea,
            scoreAFinal: 0,
            scoreB: +item.scoreb,
            scoreBFinal: 0,
            scoreC: +item.scorec,
            scoreCFinal: 0,
            judgesPointsFinal: 0
        };
        totals[item.dance].push(newDance);
    }
    //calculate the CONVERTED judges points
    for (let i = 0; i < Object.keys(totals).length; i++) {
        let dance = Object.keys(totals)[i];
        totals[dance] = JSON.parse(JSON.stringify(calcualateIndividualJudge(totals[dance], 'scoreA')));
        totals[dance] = JSON.parse(JSON.stringify(calcualateIndividualJudge(totals[dance], 'scoreB')));
        totals[dance] = JSON.parse(JSON.stringify(calcualateIndividualJudge(totals[dance], 'scoreC')));

        for (let ii = 0; ii < totals[dance].length; ii++) {
            const item = totals[dance][ii];
            item.judgesPointsFinal = item.scoreAFinal + item.scoreBFinal + item.scoreCFinal;
        }
    }

    //assign points and places
    for (let i = 0; i < Object.keys(totals).length; i++) {
        const dance = Object.keys(totals)[i];
        totals[dance].sort((a, b) => b.judgesPointsFinal - a.judgesPointsFinal);
        let place = 1;
        let lastScore = totals[dance][0].judgesPointsFinal;
        for (let ii = 0; ii < totals[dance].length; ii++) {
            const item = totals[dance][ii];
            if (item.judgesPointsFinal < lastScore) place = ii + 1; //this is the version that skips a place when there is a tie.  for example, if there is a 2 way tie for 2nd place, the next dancer gets 4th place, not 3rd.  Keeping this just in case...
            // if (item.judgesPointsFinal < lastScore) place++; //this is the version where ties dont skip at all.  for example, if there is a 2 way tie for 2nd place, the next dancer gets 3rd place, not 4th.
            item.place = place;
            item.totalPoints = placePoints[place] === undefined ? 0 : placePoints[place];
            lastScore = item.judgesPointsFinal;

            if (+item.judgesPointsFinal === 0) continue; //if the competition hasnt been judged yet, then dont update the DB
            insertString += insertString.split(',').length - 1 === 7 ? '' : ','; //add a comma if it is the first item we are adding to the string (there are already 3 commas in the string, which is why Im checking for 3)
            
            //now we insert the data into the string
            insertString += ` (${item.id}, "${item.judgesPoints}", "${item.judgesPointsFinal}", ${item.totalPoints}, ${item.place}, ${item.scoreAFinal}, ${item.scoreBFinal}, ${item.scoreCFinal})`;
        }
    }

    //old way
    /*for (let i = 0; i < Object.keys(totals).length; i++) {
        const dance = Object.keys(totals)[i];
        totals[dance].sort((a, b) => b.judgesPoints - a.judgesPoints);
        let place = 1;
        let lastScore = totals[dance][0].judgesPoints;
        for (let ii = 0; ii < totals[dance].length; ii++) {
            const item = totals[dance][ii];
            if (item.judgesPoints < lastScore) place = ii + 1; //this is the version that skips a place when there is a tie.  for example, if there is a 2 way tie for 2nd place, the next dancer gets 4th place, not 3rd.  Keeping this just in case...
            // if (item.judgesPoints < lastScore) place++; //this is the version where ties dont skip at all.  for example, if there is a 2 way tie for 2nd place, the next dancer gets 3rd place, not 4th.
            item.place = place;
            item.totalPoints = placePoints[place] === undefined ? 0 : placePoints[place];
            lastScore = item.judgesPoints;

            if (+item.judgesPoints === 0) continue; //if the competition hasnt been judged yet, then dont update the DB

            insertString += insertString.split(',').length - 1 === 7 ? '' : ','; //add a comma if it is the first item we are adding to the string (there are already 3 commas in the string, which is why Im checking for 3)
            
            //now we insert the data into the string
            insertString += ` (${item.id}, "${item.judgesPoints}", ${item.totalPoints}, ${item.place})`;
        }
    }*/
    return insertString;
}

const calcualateIndividualJudge = (danceData, judge) => {
    if (danceData[0][judge] > 0) {
        danceData.sort((a, b) => +b[judge] - +a[judge]);
        const lastNumber = danceData.length > 5 ? 6 : danceData.length-1;
        for (let i = 0; i <= lastNumber; i++) {
            danceData[i][judge + 'Final'] = placePoints[i + 1] || 0;
        }
    }
    return danceData;
}

export const reseed = (loadData, dancers, ageGroups, ogMatrix, checkedinList, comps) => {
    let dataMatrix = JSON.parse(JSON.stringify(ogMatrix));
    for (let i = 0; i < ageGroups.length; i++) {
        const ageGroup = ageGroups[i];
        if (+ageGroup.danceid === 3) dataMatrix[reverseDanceIdConverter[3]].classifications[ageGroup.classification].ageGroups.push(ageGroup.startingnumber + ' & Under ' + ageGroup.undernumber);
        else if (+ageGroup.danceid === 4) dataMatrix[reverseDanceIdConverter[4]].ageGroups.push(ageGroup.startingnumber + ' & Under ' + ageGroup.undernumber);
    }
    let entries = false;
    let insertString = 'INSERT INTO scrutineering (competition, bucket, subbucket, dance, dancenumber) VALUES';
    for (let i = 0; i < dancers.length; i++) {
        const dancer = dancers[i];
        const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
        dancer.age1 = eventDate1.diff(dob, 'years');
        dancer.age2 = eventDate2.diff(dob, 'years');
        const dances = dancer.dances.split(',');
        for (let ii = 0; ii < dances.length; ii++) {
            const competition = dances[ii];
            if (competition === '' || !comps.includes(competition)) continue;
            if (+checkedinList[dancer.dancenumber][competitionConverter[competition]] !== 1) continue;
            if (competition === 'Pre-Championship') {
                if (dataMatrix[competition].ageGroups.length === 0) { //no age groups
                    insertString = addDances(insertString, dancer.dancenumber, dataMatrix[competition].dances, entries, competition, '', '');
                    entries = true;
                }else {
                    insertString = addDancesByAgeGroup(dataMatrix[competition].ageGroups, insertString, dancer, dataMatrix, competition, entries, '');
                    entries = true;
                }
            }else if (competition === 'Pre-Premier Competition') {
                for (let iii = 0; iii < Object.keys(dataMatrix[competition].classifications).length; iii++) {
                    const classification = Object.keys(dataMatrix[competition].classifications)[iii];
                    if (dancer.classification === classification) {
                        if (dataMatrix[competition].classifications[classification].ageGroups.length === 0) {
                            insertString = addDances(insertString, dancer.dancenumber, dataMatrix[competition].classifications[classification].dances, entries, competition, classification, '');
                            entries = true;
                        }else {
                            insertString = addDancesByAgeGroup(dataMatrix[competition].classifications[classification].ageGroups, insertString, dancer, dataMatrix, competition, entries, classification);
                            entries = true;
                        }
                    }
                }
            }else {
                insertString = addDancesByAgeGroup(Object.keys(dataMatrix[competition].ageGroups), insertString, dancer, dataMatrix, competition, entries, '');
                entries = true;
            }
        }
    }
    const insertData = new FormData();
    insertData.set('action', 'insertScrutineering');
    insertData.set('insertString', insertString);
    Axios.post(apiString, insertData).then(() => {
        loadData(true);
    });
}

const addDances = (insertString, danceNumber, dances, entries, competition, bucket, subbucket) => {
    for (let i = 0; i < dances.length; i++) {
        const dance = dances[i];
        if (entries) insertString += ',';
        insertString += ` ("${competition}", "${bucket}", "${subbucket}", "${dance}", ${danceNumber})`;
        entries = true;
    }
    return insertString;
}

const addDancesByAgeGroup = (ageGroups, insertString, dancer, dataMatrix, competition, entries, classification) => {
    const age = dancer['age' + dataMatrix[competition].day];
    for (let i = 0; i < ageGroups.length; i++) {
        const ageGroup = ageGroups[i];
        const min = ageGroup.split(' & Under ')[0];
        const max = ageGroup.split(' & Under ')[1];
        if (age >= min && age < max) {
            const bucket = classification === '' ? ageGroup : classification;
            const subbucket = classification === '' ? '' : ageGroup;
            let dances = [];
            if (competition === 'Pre-Championship') dances = dataMatrix[competition].dances;
            else if (competition === 'Pre-Premier Competition') dances = dataMatrix[competition].classifications[classification].dances;
            else dances = dataMatrix[competition].ageGroups[ageGroup];
            insertString = addDances(insertString, dancer.dancenumber, dances, entries, competition, bucket, subbucket);
            entries = true;
            break;
        }
    }
    return insertString
}