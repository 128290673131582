
import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Header, TextArea, Select, Checkbox,  } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { saveDancerInfo } from './utilities';

const classifications = ['Primary', 'Beginner', 'Novice', 'Intermediate', 'Premier'];
const classificationList = classifications.map(type => ({key: type, text: type, value: type}));
classificationList.unshift({key: '', text: '', value: '', disabled: true});

const EditDancerModal = ({info, setDancerModal, masterDanceList, setLoading, handleCloseModal}) => {
    const [dancer, setDancer] = useState({});
    const [danceList, setDanceList] = useState({0: [], 1: []});

    useEffect(() => {
        const copy = JSON.parse(JSON.stringify(info));
        copy.dances = copy.dances.split(',');
        setDancer(copy);
        prepDanceList(copy);
    }, []);

    const prepDanceList = registrant => {
        const list = {0: [], 1: []};
        for (let i = 0; i < masterDanceList.length; i++) {
            const dance = masterDanceList[i];
            if (dance[registrant.classification] !== '9999') list[+dance.day-1].push({name: dance.dance, price: +dance[registrant.classification]});
        }
        setDanceList(list);
        registrant.birthdate = new Date(registrant.birthdatestring);
        if (registrant.birthdate.toString() === 'Invalid Date') registrant.birthdate = '';
        verifyDanceSelections(list, registrant);
    }

    const verifyDanceSelections = (list, registrant) => {
        let remove = [];
        for (let i = 0; i < registrant.dances.length; i++) {
            const dance = registrant.dances[i];
            if (!list[0].some(d => d.name === dance) && !list[1].some(d => d.name === dance)) remove.push(dance);
        }
        let newDances = JSON.parse(JSON.stringify(registrant.dances));
        newDances = newDances.filter(d => !remove.includes(d));
        setDancer({...registrant, dances: newDances});
    }

    const handleFormChange = (e, {id, value}) => {
        if (id === 'highlight') value = +dancer.highlight === 1 ? 0 : 1;
        setDancer({...dancer, [id]: value});
        const newDancer = structuredClone(dancer);
        newDancer[id] = value;
        if (id === 'classification') prepDanceList(newDancer);
    }

    const validateEmail  = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!regex.test(dancer.teacheremailaddress)) setDancer({...dancer, teacheremailaddress: ''});
    }

    const handleDanceClick = dance => {
        let dances = JSON.parse(JSON.stringify(dancer.dances));
        if (dances.includes(dance)) dances = dances.filter(d => d !== dance);
        else dances.push(dance);
        setDancer({...dancer, dances});
    }

    const handleDoneClick = () => {
        setLoading(true);
        saveDancerInfo(dancer).then(() => {
            handleCloseModal();
        });
    }

    const checkDisabled = () => {
        return (dancer.firstname === '' || dancer.lastname === '' || dancer.birthdate === '' || dancer.birthdate === null || dancer.fulladdress === '' || dancer.classification === '' || dancer.teacherlastname === '' || dancer.teacheremailaddress === '' || dancer.fustanumber === '' || dancer.dances?.length === 0);
    }

    return (
        <Modal open closeOnDimmerClick={false} closeOnEscape={false}>
            <Header as="h3" textAlign="center">Dancer Info</Header>
            <Modal.Content>
                <p style={{margin: 0, textAlign: 'center', fontWeight: 'bold'}}>Contact Info</p>
                <p style={{margin: 0, textAlign: 'center', fontSize: 12}}>Name: {dancer.contactname}</p>
                <p style={{margin: 0, textAlign: 'center', fontSize: 12}}>Phone: {dancer.contactphonenumber}</p>
                <p style={{margin: 0, textAlign: 'center', fontSize: 12}}>Email Address: <a href={'mailto:' + dancer.contactemailaddress} rel="noreferrer">{dancer.contactemailaddress}</a></p>
                <Form style={{width: 250, margin: '20px auto 0 auto'}}>
                    <Form.Field id="firstname" control={Input} value={dancer.firstname} label="First Name" fluid onChange={handleFormChange} />
                    <Form.Field id="lastname" control={Input} value={dancer.lastname} label="Last Name" fluid onChange={handleFormChange} />
                    <Form.Field id="birthdate" control={SemanticDatepicker} value={dancer.birthdate} label="Birth Date (mm/dd/yyyy)" fluid onChange={handleFormChange} format="MM/DD/YYYY" showToday={false} />
                    <Form.Field id="fulladdress" control={TextArea} value={dancer.fulladdress} label="Address (include country if outside USA)" placeholder="Example:&#10;123 N Sesame Street&#10;Anytown, IL 12345" onChange={handleFormChange} />
                    <Form.Field id="classification" control={Select} value={dancer.classification} options={classificationList} label="Classification" fluid onChange={handleFormChange} />
                    <Form.Field id="teacherlastname" control={Input} value={dancer.teacherlastname} label="Teacher's Last Name" fluid onChange={handleFormChange} />
                    <Form.Field id="teacheremailaddress" control={Input} value={dancer.teacheremailaddress} label="Teacher's Email Address" fluid onChange={handleFormChange} onBlur={validateEmail} />
                    <Form.Field id="fustanumber" control={Input} value={dancer.fustanumber} label="ScotsDance #" fluid onChange={handleFormChange} />

                    <p style={{textAlign: 'center', margin: '40px 0 4px 0', fontWeight: 'bold'}}>Saturday 02/15/2025</p>
                    {danceList[0].length === 0 && <p style={{textAlign: 'center', margin: '0 0 4px 0', fontStyle: 'italic'}}>No dances available for this classification</p>}
                    {danceList[0].map((dance, index) => {
                        return (
                            <Form.Field key={index} control={Checkbox} label={dance.name} checked={dancer.dances.includes(dance.name)} onChange={() => handleDanceClick(dance.name, dance.price)} />
                        );
                    })}
                    <p style={{textAlign: 'center', margin: '30px 0 4px 0', fontWeight: 'bold'}}>Sunday 02/16/2025</p>
                    {danceList[1].length === 0 && <p style={{textAlign: 'center', margin: '0 0 4px 0', fontStyle: 'italic'}}>No dances available for this classification</p>}
                    {danceList[1].map((dance, index) => {
                        return (
                            <Form.Field key={index} control={Checkbox} label={dance.name} checked={dancer.dances.includes(dance.name)} onChange={() => handleDanceClick(dance.name)} />
                        );
                    })}
                    <Form.Field style={{marginTop: 50}} id="highlight" control={Checkbox} label="Highlight" checked={+dancer.highlight === 1} onChange={handleFormChange} />
                    <Form.Field id="notes" control={TextArea} style={{width: 200, height: 100, resize: 'none', marginTop: 0}} placeholder="Notes" value={dancer.notes === null ? '' : dancer.notes} onChange={handleFormChange} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setDancerModal('')}>Cancel</Button>
                <Button primary onClick={handleDoneClick} disabled={checkDisabled()}>Done</Button>
            </Modal.Actions>
        </Modal> 
    );
}

export default EditDancerModal;