const pages = ['Dancers', 'Age Groups', 'Registration', 'Downloads', 'Scrutineering'];

const TopBar = ({page, setPage}) => {

    const handleLinkClick = (pageName) => {
        if (pageName !== page) setPage(pageName);
    }

    return (
            <div style={{width: '100%', height: 50, outline: '1px solid #ebebeb', textAlign: 'center'}}>
                {pages.map((pageName, index) => {
                    return (
                        <div key={index} style={{display: 'inline-block', marginLeft: 20, marginRight: 20, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: (pageName === page ? '#0494e1' : '#444'), cursor: (pageName === page ? 'default' : 'pointer')}} onClick={() => handleLinkClick(pageName)}>
                            {pageName}
                        </div>
                    );
                })}
                <p style={{position: 'absolute', top: 50, left: '50%', transform: 'translateX(-50%)', color: 'red'}}>Most changes require a reseed!</p>
            </div>
    );
}

export default TopBar;