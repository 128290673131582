import Axios from 'axios';
import apiString from './apiString';
import moment from 'moment';

export const capitalizeWord = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const saveDancerInfo = dancer => {
    return new Promise(resolve => {
        const formdata = new FormData();
        formdata.set('action', 'updateDancerInfo');
        formdata.set('dancer', JSON.stringify(dancer));
        Axios.post(apiString, formdata).then(() => {
            resolve();
        });
    });
    
}

export const makeAgeGroupTitle = (low, high) => {
    if (+low === 1) return +high-1 + ' & Under';
    if (+high === 49) return low + ' & Over';
    return low + ' & Under ' + high;
}

export const eventDate1 = moment('02/15/2025', 'MM/DD/YYYY');
export const eventDate2 = moment('02/16/2025', 'MM/DD/YYYY');