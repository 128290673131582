import { useEffect, useState } from 'react';
import { Form, Select, Button, Table, Icon, Modal, Header } from 'semantic-ui-react';
import Axios from 'axios';
import apiString from '../components/apiString';
import AgeGroupModal from '../components/editAgeGroupModal';
import { eventDate1, eventDate2, makeAgeGroupTitle } from '../components/utilities';
import moment from 'moment';
import DancerTable from '../components/dancerTable';

let danceList = [];
const ageGroupTableHeaders = ['Age Group', '# of Dancers', 'Edit', 'Delete'];
const classifications = ['Primary', 'Beginner', 'Novice', 'Intermediate'];
const classificationList = classifications.map(type => ({key: type, text: type, value: type}));

const AgeGroups = ({setLoading}) => {
    const [dances, setDances] = useState({});
    const [dancers, setDancers] = useState([]);
    const [selectedDance, setSelectedDance] = useState('');
    const [ageGroups, setAgeGroups] = useState([]);
    const [ageGroupModal, setAgeGroupModal] = useState('');
    const [confirmDeleteAgeGroup, setConfirmDeleteAgeGroup] = useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
    const [selectedClass, setSelectedClass] = useState('none');
    const [masterDanceList, setMasterDanceList] = useState([]);

    useEffect(() => {
        pullDances();
    }, []);

    useEffect(() => {
        if (selectedDance !== '') pullAgeGroupData(dances[selectedDance].id, selectedClass);
    }, [selectedDance]);

    useEffect(() => {
        if (selectedDance === 'Pre-Premier Competition') pullAgeGroupData(dances[selectedDance].id, selectedClass);
    }, [selectedClass]);

    const pullDances = (test, existingSwitch) => {
        setLoading(true);
        setTimeout(() => {
            let pulls = [];
            const formData = new FormData();
            formData.set('action', 'getDancerList');
            formData.set('searchString', '');
            pulls.push(Axios.post(apiString, formData));
    
            const pullDances = new FormData();
            pullDances.set('action', 'getDanceList');
            pulls.push(Axios.post(apiString, pullDances));
            Axios.all(pulls).then(response => {
                danceList = response[1].data.map(dance => ({key: dance.dance, text: dance.dance, value: dance.dance}));
                danceList.sort((a, b) => {
                    return a.text > b.text ? 1 : -1;
                });
                let danceObject = {};
                response[1].data.forEach(dance => {
                    danceObject[dance.dance] = dance;
                });
                setDances(danceObject);
                setDancers(response[0].data);
                setMasterDanceList(response[1].data);
                if (existingSwitch) changeSelectedDance(null, {value: selectedDance}, true, response[0].data);
                else changeSelectedDance(null, {value: danceList[0].value});
            });
        }, 100);
    }

    const changeSelectedDance = (e, {value}, existingSwitch, ogDancers) => {
        setSelectedDance(value);
        if (!existingSwitch) setSelectedAgeGroup('');
        if (existingSwitch) pullAgeGroupData(dances[selectedDance].id, selectedClass, ogDancers);
        else setSelectedClass(value === 'Pre-Premier Competition' ? 'Premier' : 'none');
    }

    const pullAgeGroupData = (danceId, classification, ogDancers) => {
        const theDancers = JSON.parse(JSON.stringify(ogDancers || dancers));
        setLoading(true);
        const pullAgeGroups = new FormData();
        pullAgeGroups.set('action', 'getAgeGroups');
        pullAgeGroups.set('classification', classification);
        pullAgeGroups.set('danceId', danceId);
        Axios.post(apiString, pullAgeGroups).then(response => {
            let matches = [];
            let potentials = [];
            const ageGroupList = response.data.map(ageGroup => {
                ageGroup.count = 0;
                ageGroup.dancers = [];
                ageGroup.title = makeAgeGroupTitle(ageGroup.startingnumber, ageGroup.undernumber);
                const eventDate = +dances[selectedDance].day === 1 ? eventDate1 : eventDate2;
                for (let i = 0; i < theDancers.length; i++) {
                    const dancer = theDancers[i];
                    const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
                    const age = eventDate.diff(dob, 'years');
                    const ageMatch = age >= +ageGroup.startingnumber && age < +ageGroup.undernumber;
                    const danceMatch = (dancer.dances.includes(selectedDance) && (dancer.classification === classification || classification === 'none'));
                    if (ageMatch && danceMatch) {
                        ageGroup.count++;
                        ageGroup.dancers.push(dancer);
                        matches.push(i);
                    }
                }
                return ageGroup;
            });
            for (let i = 0; i < theDancers.length; i++) {
                const dancer = theDancers[i];
                const danceMatch = (dancer.dances.includes(selectedDance) && (dancer.classification === classification || classification === 'none'));
                if (danceMatch && !matches.includes(i)) potentials.push(i);
            }
            const unassignedDancers = potentials.map(index => theDancers[index]);
            ageGroupList.push({title: 'Unassigned', count: potentials.length, dancers: JSON.parse(JSON.stringify(unassignedDancers))});
            setAgeGroups(ageGroupList);
            setLoading(false);
        });
    }

    const handleDeleteAgeGroup = () => {
        setLoading(true);
        const formData = new FormData();
        formData.set('action', 'deleteAgeGroup');
        formData.set('ageGroupId', confirmDeleteAgeGroup);
        Axios.post(apiString, formData).then(() => {
            setLoading(false);
            setConfirmDeleteAgeGroup('');
            pullAgeGroupData(dances[selectedDance].id, selectedClass);
        });
    }

    const handleRowClick = index => {
        if (selectedAgeGroup === index) setSelectedAgeGroup('');
        else setSelectedAgeGroup(index);
    }

    const changeSelectedClass = (e, {value}) => {
        setSelectedAgeGroup('');
        setSelectedClass(value);
    }

    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <Form style={{width: 250, margin: '0 auto'}}>
                <Form.Field control={Select} value={selectedDance} options={danceList} label="Competition" fluid onChange={changeSelectedDance} />
                {selectedDance === 'Pre-Premier Competition' &&
                    <Form.Field control={Select} value={selectedClass} options={classificationList} label="Classification" fluid onChange={changeSelectedClass} />
                }
            </Form>

            <Button primary style={{marginTop: 60}} onClick={() => setAgeGroupModal('add')}>Add Age Group</Button>

            <Table style={{width: 1000, margin: '10px auto', textAlign: 'center', cursor: 'pointer'}} size="small" compact celled sortable selectable>
                <Table.Header>
                    <Table.Row>
                        {ageGroupTableHeaders.map((header, index) => {
                            return <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                        })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {ageGroups.map((ageGroup, index) => {
                        return (
                            <Table.Row key={index} onClick={() => handleRowClick(index)}>
                                <Table.Cell>{ageGroup.title}</Table.Cell>
                                <Table.Cell>{ageGroup.count}</Table.Cell>
                                <Table.Cell>
                                    {ageGroup.title !== 'Unassigned' && <Icon name="edit outline" style={{color: '#0494e1'}} onClick={() => setAgeGroupModal(index)} />}
                                </Table.Cell>
                                <Table.Cell>
                                    {ageGroup.title !== 'Unassigned' && <Icon name="trash alternate outline" color="red" onClick={() => setConfirmDeleteAgeGroup(ageGroup.id)} />}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>

            {selectedAgeGroup !== '' &&
                <>
                    <p style={{marginTop: 50, marginBottom: 0, fontWeight: 'bold'}}>{ageGroups[selectedAgeGroup].title}</p>
                    <DancerTable key={ageGroups[selectedAgeGroup].dancers} setLoading={setLoading} ogDancers={ageGroups[selectedAgeGroup].dancers} ogMasterDanceList={masterDanceList} searchString="" pullDancers={pullDances} checkedInSelect={['None']} />
                </>
            }


            {ageGroupModal !== '' && 
                <AgeGroupModal setAgeGroupModal={setAgeGroupModal} ageGroupInfo={{startingNumber: (ageGroupModal === 'add' ? '' : +ageGroups[ageGroupModal].startingnumber), underNumber: (ageGroupModal === 'add' ? '' : +ageGroups[ageGroupModal].undernumber)}} setLoading={setLoading} ageGroupId={ageGroupModal === 'add' ? 'add' : ageGroups[ageGroupModal].id} danceId={dances[selectedDance].id} pullAgeGroupData={pullAgeGroupData} classification={selectedClass} />
            }

            {confirmDeleteAgeGroup !== '' &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Delete Age Group</Header>
                    <Modal.Content>
                        <p>Are you sure?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={handleDeleteAgeGroup}>Yes, Do It!</Button>
                        <Button negative onClick={() => setConfirmDeleteAgeGroup('')}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
        </div>
    );
}

export default AgeGroups;