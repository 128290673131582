import { useState } from 'react';
import 'semantic-ui-css/semantic.min.css'
import TopBar from './components/topbar';
import Barrier from './pages/barrier';
import Dancers from './pages/dancers';
import Spinner from './components/spinner';
import AgeGroups from './pages/agegroups';
import Downloads from './pages/downloads';
import Scrutineering from './pages/scrutineering';
import Registration from './pages/registration';

function App() {
	const [page, setPage] = useState('Barrier');
	const [loading, setLoading] = useState(false);

	return (
		<>
			{page !== 'Barrier' && <TopBar page={page} setPage={setPage} />}
			{page === 'Barrier' && <Barrier setPage={setPage} />}
			{page === 'Dancers' && <Dancers setLoading={setLoading} />}
			{page === 'Age Groups' && <AgeGroups setLoading={setLoading} />}
			{page === 'Registration' && <Registration setLoading={setLoading} />}
			{page === 'Downloads' && <Downloads setLoading={setLoading} />}
			{page === 'Scrutineering' && <Scrutineering setLoading={setLoading} />}
			{loading && <Spinner text='Loading...' />}
		</>
	);
}

export default App;