
import { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';

const inputStyle = {width: '100%', border: 'none', outline: 'none', textAlign: 'center'};

const competitionConverter = {
    'Open Championship': 'Open',
    'Irish Jig Challenge - 5 Steps': 'Jig',
    'Scottish National Dance Premiership': 'Nationals',
    'Pre-Premier Competition': 'Pre-Premier',
    'Pre-Championship': 'Pre-Champ',
};

const ScrutineerTableModal = ({ogList, competition, bucket, subbucket, dance, handleDoneClick, judgeCount}) => {
    const [list, setList] = useState(JSON.parse(JSON.stringify(ogList)));
    const [shiftButton, setShiftButton] = useState(false);
    const [headers, setHeaders] = useState(['Dancer #', 'Judge A']);

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);  
        if (judgeCount === 3) setHeaders(['Dancer #', 'Judge A', 'Judge B', 'Judge C']);

        setTimeout(() => {
            const element = document.getElementById('00');
            element.focus();
            element.select();
        }, 50);

        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    const changeScore = (index, whichOne, value) => {
        let newList = JSON.parse(JSON.stringify(list));
        newList[index][whichOne] = value;
        setList(newList);
    }

    const handleKeyDown = (e, row, column) => {
        const resetColumn = judgeCount - 1;
        if (e.key === 'Shift') setShiftButton(true);
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            let newRow = row;
            let newColumn = column;
            const shiftModifier = shiftButton ? -1 : 1;
            if (e.key === 'Enter') {
                newRow = row + (1*shiftModifier);
                newColumn = column;
                if (newRow >= list.length) {
                    newRow = 0;
                    newColumn = column + 1;
                }
                if (newRow < 0) {
                    newRow = list.length-1;
                    newColumn = column - 1;
                }
            }
            if (e.key === 'Tab') {
                newColumn = column + (1*shiftModifier);
                newRow = row;
                if (newColumn > resetColumn) {
                    newColumn = 0;
                    newRow = row + 1;
                }
                if (newColumn < 0) {
                    newColumn = resetColumn;
                    newRow = row - 1;
                }
            }
            let finalString = newRow.toString() + newColumn.toString();
            if (row === list.length-1 && column === resetColumn && !shiftButton) finalString = '00';
            if (row === 0 && column === 0 && shiftButton) finalString = (list.length-1).toString() + resetColumn.toString();
            const element = document.getElementById(finalString);
            element.focus();
            element.select();
        }
    }

    const handleKeyUp = e => {
        if (e.key === 'Shift') setShiftButton(false);
    }

    const makeTitle = () => {
        let title = competitionConverter[competition];
        if (bucket !== '') title += ' - ' + bucket;
        if (subbucket !== '') title += ' - ' + subbucket;
        title += ' - ' + dance;
        return title;
    }

    return (
        <Modal style={{width: 400}}open closeOnDimmerClick={false} closeOnEscape={false}>
            <p style={{textAlign: 'center', fontSize: 12, color: '#444', fontWeight: 'bold', paddingTop: 10, marginBottom: 0}}>{makeTitle()}</p>
            <Modal.Content>
                    <Table compact celled striped>
                        <Table.Header>
                            <Table.Row>
                                {headers.map(header => <Table.HeaderCell key={header} style={{width: 70}}>{header}</Table.HeaderCell>)}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {list.map((row, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell>{row.dancenumber}</Table.Cell>
                                    <Table.Cell><input id={i + '0'} type="text" value={row.scorea} onChange={e => changeScore(i, 'scorea', e.target.value)} style={inputStyle} onKeyDown={e => handleKeyDown(e, i, 0)} /></Table.Cell>
                                    {judgeCount === 3 &&
                                        <>
                                            <Table.Cell><input id={i + '1'} type="text" value={row.scoreb} onChange={e => changeScore(i, 'scoreb', e.target.value)} style={inputStyle} onKeyDown={e => handleKeyDown(e, i, 1)} /></Table.Cell>
                                            <Table.Cell><input id={i + '2'} type="text" value={row.scorec} onChange={e => changeScore(i, 'scorec', e.target.value)} style={inputStyle} onKeyDown={e => handleKeyDown(e, i, 2)} /></Table.Cell>
                                        </>
                                    }
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => handleDoneClick(list)}>Done</Button>
            </Modal.Actions>
        </Modal> 
    );
}

export default ScrutineerTableModal;