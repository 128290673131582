import Axios from 'axios';
import apiString from '../components/apiString';
import moment from 'moment';
import { eventDate1 } from '../components/utilities';
import { generateLineUpSheets, generateResultSheets } from '../components/printFunctions';

const compConverter = {
    open: 'Open Champs',
    jig: 'Jig',
    prechamps: 'Pre-Champs',
    precomp: 'Pre-Premier Comp',
    national: 'Nationals'
};

const Downloads = ({setLoading}) => {

    const prepFullDancerTable = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'getDancerList');
        data.set('searchString', '');
        Axios.post(apiString, data).then(response => {
            const headers = ['Timestamp', 'Dancer Number', 'First Name', 'Last Name', 'Birthdate', 'Address', 'Classification', 'Teacher', 'Teacher Email', 'Contact Email', 'ScotsDance Number', 'Dances', 'Signature'];
            let rows = [];
            rows.push(headers);
            for (let i = 0; i < response.data.length; i++) {
                const dancer = response.data[i];
                rows.push([moment.unix(dancer.timestamp).format('MM/DD/YYYY h:mmA'), dancer.dancenumber, dancer.firstname, dancer.lastname, dancer.birthdatestring, dancer.fulladdress.replaceAll('\n', ' ').replaceAll('#', '').replaceAll(',', ''), dancer.classification, dancer.teacherlastname.replaceAll(',', '-'), dancer.teacheremailaddress, dancer.contactemailaddress, dancer.fustanumber.replaceAll('#', ''), dancer.dances.replaceAll(',', ' // '), dancer.signature]);
            }
            prepDownload('Full Dancer Table', rows);
        });
    }

    const prepScrutineeringImportFile = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'getDancerList');
        data.set('searchString', '');
        Axios.post(apiString, data).then(response => {
            const headers = ['Number', 'Firstname', 'Lastname', 'Age', 'Address 1', 'Town', 'Zip', 'Teacher'];
            let rows = [];
            rows.push(headers);
            for (let i = 0; i < response.data.length; i++) {
                const dancer = response.data[i];
                const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
                dancer.age = eventDate1.diff(dob, 'years');
                const address1 = dancer.fulladdress.split('\n')[0].replaceAll('#', '').replaceAll(',', '');
                const line2 = dancer.fulladdress.split('\n')[1];
                const town = line2?.split(',')[0];
                const zip = line2?.split(' ')[2];
                rows.push([dancer.dancenumber, dancer.firstname, dancer.lastname, dancer.age, address1, town, zip, dancer.teacherlastname.replaceAll(',', '-')]);
            }
            prepDownload('Scrutineering Import File', rows);
        });
    }

    const prepDownload = (fileName, rows) => {
        const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
    }

    const handleLineupSheetClick = async comp => {
        setLoading(true);
        const pdf = await generateLineUpSheets(comp);
        window.open(pdf.output('bloburl'), '_blank');
        setLoading(false);
    }

    const handleResultSheetClick = async comp => {
        setLoading(true);
        const pdf = await generateResultSheets(comp);
        window.open(pdf.output('bloburl'), '_blank');
        setLoading(false);
    }

    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <div style={{width: '100%', height: 20}}></div>
            <div style={{width: 400, border: '1px solid #bcbcbc', borderRadius: 3, margin: '0 auto', padding: '4px 12px 12px 12px'}}>
                <p style={{fontWeight: 'bold', fontSize: 15, marginBottom: 12}}>Exports</p>
                    <p style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={prepFullDancerTable}>Full Dancer Table</p>
                    <p style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={prepScrutineeringImportFile}>Scrutineering Import File</p>
            </div>

            <div style={{width: '100%', height: 48}}></div>
            <div style={{width: 400, border: '1px solid #bcbcbc', borderRadius: 3, margin: '0 auto', padding: '4px 12px 12px 12px'}}>
                <p style={{fontWeight: 'bold', fontSize: 15, marginBottom: 12}}>Line Up Sheets</p>
                {Object.keys(compConverter).map((comp, index) => {
                    return <p key={index} style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={() => handleLineupSheetClick(comp)}>{compConverter[comp]}</p>
                })}
            </div>

            <div style={{width: '100%', height: 48}}></div>
            <div style={{width: 400, border: '1px solid #bcbcbc', borderRadius: 3, margin: '0 auto', padding: '4px 12px 12px 12px'}}>
                <p style={{fontWeight: 'bold', fontSize: 15, marginBottom: 12}}>Results Sheets</p>
                {Object.keys(compConverter).map((comp, index) => {
                    return <p key={index} style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={() => handleResultSheetClick(comp)}>{compConverter[comp]}</p>
                })}
            </div>
            
        </div>
    );
}

export default Downloads;