import Axios from 'axios';
import apiString from '../components/apiString';
import { Button, Modal, Header, Form, Select, Checkbox } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { makeAgeGroupTitle } from '../components/utilities';
import ScrutineerTableModal from '../components/scrutineerTableModal';
import { crunchResults, reseed } from '../components/scrutineeringLogic';

const compConverter = {
    open: 'Open Champs',
    jig: 'Jig',
    prechamps: 'Pre-Champs',
    precomp: 'Pre-Premier Comp',
    national: 'Nationals'
};

const fullNameConverter = {
    open: 'Open Championship',
    jig: 'Irish Jig Challenge - 5 Steps',
    prechamps: 'Pre-Championship',
    precomp: 'Pre-Premier Competition',
    national: 'Scottish National Dance Premiership'
}

const Scrutineering = ({setLoading}) => {
    const [reseedConfirm, setReseedConfim] = useState(false);
    const [masterMatrix, setMasterMatrix] = useState({});
    const [checkedinList, setCheckedinList] = useState({});
    const [ogData, setOgData] = useState({});
    const [scrutineeringTables, setScrutineeringTables] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [selectedBucket, setSelectedBucket] = useState('');
    const [selectedSubbucket, setSelectedSubbucket] = useState('');
    const [selectedDance, setSelectedDance] = useState('');
    const [showTableModal, setShowTableModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [publishOptions, setPublishOptions] = useState({});
    const [showSeedModal, setShowSeedModal] = useState(false);
    const [checkedSeeds, setCheckedSeeds] = useState([false, false, false, false, false]);
    const [confirmSeedAction, setConfirmSeedAction] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = (resetSwitch) => {
        setLoading(true);
        let pulls = [];
        const data = new FormData();
        data.set('action', 'getScrutineeringTables');
        pulls.push(Axios.post(apiString, data));
        const data2 = new FormData();
        data2.set('action', 'getScrutineeringData');
        pulls.push(Axios.post(apiString, data2));
        Axios.all(pulls).then(response => {
            let reference = {};
            for (let i = 0; i < response[1].data.dancers.length; i++) {
                const dancer = response[1].data.dancers[i];
                reference[dancer.dancenumber] = dancer;
            }
            setCheckedinList(reference);
            let dataMatrix = {};
            const ogMatrix = JSON.parse(JSON.stringify(response[1].data.dataMatrix));
            for (let i = 0; i < response[0].data.length; i++) {
                const item = response[0].data[i];
                const comp = item.competition;
                if (!dataMatrix[comp]) dataMatrix[comp] = {judgeCount: ogMatrix[comp].judgeCount, bucketType: '', bucketItems: {}, dances: []};
                if (item.bucket !== '') {
                    dataMatrix[comp].bucketType = item.bucket.includes('&') ? 'Age Group' : 'Class';
                    if (!dataMatrix[comp].bucketItems[item.bucket]) dataMatrix[comp].bucketItems[item.bucket] = {subbucketType: '', subbucketItems: {}, dances: []};
                    if (item.subbucket !== '') {
                        dataMatrix[comp].bucketItems[item.bucket].subbucketType = item.subbucket.includes('&') ? 'Age Group' : 'Class';
                        if (!dataMatrix[comp].bucketItems[item.bucket].subbucketItems[item.subbucket]) dataMatrix[comp].bucketItems[item.bucket].subbucketItems[item.subbucket] = {dances: []};
                        if (!dataMatrix[comp].bucketItems[item.bucket].subbucketItems[item.subbucket].dances.includes(item.dance)) dataMatrix[comp].bucketItems[item.bucket].subbucketItems[item.subbucket].dances.push(item.dance);
                    }else {
                        if (!dataMatrix[comp].bucketItems[item.bucket].dances.includes(item.dance)) dataMatrix[comp].bucketItems[item.bucket].dances.push(item.dance);
                    }
                }else {
                    if (!dataMatrix[comp].dances.includes(item.dance)) dataMatrix[comp].dances.push(item.dance);
                }
            }
            setScrutineeringTables(response[0].data);
            setMasterMatrix(dataMatrix);
            setOgData(response[1].data);
            if (resetSwitch) {
                setSelectedCompetition('');
                setSelectedBucket('');
                setSelectedSubbucket('');
                setSelectedDance('');
            }
            setLoading(false);
        });
    }

    const changeSelectedCompetition = (e, {value}) => {
        setSelectedCompetition(value);
        setSelectedBucket('');
        setSelectedSubbucket('');
        setSelectedDance('');
    }

    const changeSelectedBucket = (e, {value}) => {
        setSelectedBucket(value);
        setSelectedSubbucket('');
        setSelectedDance('');
    }

    const changeSelectedSubbucket = (e, {value}) => {
        setSelectedSubbucket(value);
        setSelectedDance('');
    }

    const changeSelectedDance = (e, {value}) => {
        setSelectedDance(value);
    }

    const getCompetitionList = () => {
        const competitionList = [];
        competitionList.push({key: '', text: '', value: '', disabled: true});
        for (let i = 0; i < Object.keys(masterMatrix).length; i++) {
            const competition = Object.keys(masterMatrix)[i];
            competitionList.push({key: competition, text: competition, value: competition});
        }
        competitionList.sort((a, b) => {
            if (a.key < b.key) return -1;
            if (a.key > b.key) return 1;
            return 0;
        });
        return competitionList;
    }

    const getBucketList = () => {
        const bucketList = [];
        bucketList.push({key: '', text: '', value: '', disabled: true});
        for (let i = 0; i < Object.keys(masterMatrix[selectedCompetition].bucketItems).length; i++) {
            const bucket = Object.keys(masterMatrix[selectedCompetition].bucketItems)[i];
            const bucketText = masterMatrix[selectedCompetition].bucketType === 'Class' ? bucket : makeAgeGroupTitle(bucket.split(' & Under ')[0], bucket.split(' & Under ')[1]);
            bucketList.push({key: bucket, text: bucketText, value: bucket});
        }
        bucketList.sort((a, b) => {
            if (masterMatrix[selectedCompetition].bucketType === 'Class') {
                if (a.key < b.key) return -1;
                if (a.key > b.key) return 1;
                return 0;
            }else {
                let numA = parseInt(a.key.match(/^\d+/), 10);
                let numB = parseInt(b.key.match(/^\d+/), 10);
                return numA - numB; // Compare these numbers to sort
            }
            
        });
        return bucketList;
    }

    const getSubbucketList = () => {
        const subbucketList = [];
        subbucketList.push({key: '', text: '', value: '', disabled: true});
        for (let i = 0; i < Object.keys(masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketItems).length; i++) {
            const subbucket = Object.keys(masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketItems)[i];
            const subbucketText = masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketType === 'Class' ? subbucket : makeAgeGroupTitle(subbucket.split(' & Under ')[0], subbucket.split(' & Under ')[1]);
            subbucketList.push({key: subbucket, text: subbucketText, value: subbucket});
        }
        subbucketList.sort((a, b) => {
            if (a.key < b.key) return -1;
            if (a.key > b.key) return 1;
            return 0;
        });
        return subbucketList;
    }

    const getDanceList = () => {
        const danceList = [];
        let list = [];
        if (selectedSubbucket !== '') list = masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketItems[selectedSubbucket].dances;
        else if (selectedBucket !== '') list = masterMatrix[selectedCompetition].bucketItems[selectedBucket].dances;
        else list = masterMatrix[selectedCompetition].dances;
        for (let i = 0; i < list.length; i++) {
            const dance = list[i];
            const danceObject = {key: dance, text: dance, value: dance};
            if (checkDanceCompletion(dance)) danceObject.icon = {name: 'check', color: 'green'};
            danceList.push(danceObject);
        }
        return danceList;
    }

    const checkDanceCompletion = dance => {
        const resultsList = scrutineeringTables.filter(result => result.competition === selectedCompetition && result.bucket === selectedBucket && result.subbucket === selectedSubbucket && result.dance === dance);
        const totalExpected = masterMatrix[selectedCompetition].judgeCount * resultsList.length;
        let totalCompleted = 0;
        for (let i = 0; i < resultsList.length; i++) {
            const result = resultsList[i];
            if (+result.scorea > 0) totalCompleted++;
            if (masterMatrix[selectedCompetition].judgeCount === 3) {
                if (+result.scoreb > 0) totalCompleted++;
                if (+result.scorec > 0) totalCompleted++;
            }
        }
        return totalCompleted === totalExpected;
    }

    const getTableData = () => {
        const tableData = [];
        for (let i = 0; i < scrutineeringTables.length; i++) {
            const table = scrutineeringTables[i];
            if (table.competition === selectedCompetition && table.bucket === selectedBucket && table.subbucket === selectedSubbucket && table.dance === selectedDance) tableData.push(table);
        }
        return tableData;
    }

    const handleDoneClick = rows => {
        setLoading(true);
        setShowTableModal(false);
        const data = new FormData();
        data.set('action', 'updateScrutineering');
        data.set('rows', JSON.stringify(rows));
        Axios.post(apiString, data).then(() => {
            loadData();
        });
    }

    const handlePublishClick = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'pullResultPublishOptions');
        Axios.post(apiString, data).then(response => {
            const newOptions = {};
            for (let i = 0; i < response.data.length; i++) {
                newOptions[response.data[i].competition] = +response.data[i].publish === 1 ? true : false;
            }
            setPublishOptions(newOptions);
            setLoading(false);
            setShowPublishModal(true);
        });
    }

    const handlePublishChange = comp => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'updateResultPublishOptions');
        data.set('competition', comp);
        data.set('publish', publishOptions[comp] ? 0 : 1);
        Axios.post(apiString, data).then(() => {
            const newOptions = {...publishOptions};
            newOptions[comp] = !publishOptions[comp];
            setPublishOptions(newOptions);
            setLoading(false);
        });
    }

    const openSeedModal = () => {
        setCheckedSeeds([false, false, false, false, false]);
        setShowSeedModal(true);
    }

    const changeSeedOption = (index) => {
        let newChecked = JSON.parse(JSON.stringify(checkedSeeds));
        newChecked[index] = !newChecked[index];
        setCheckedSeeds(newChecked);
    }

    const wipeScrutineering = () => {
        setLoading(true);
        const list = [];
        for (let i = 0; i < Object.keys(compConverter).length; i++) {
            const comp = Object.keys(compConverter)[i];
            if (checkedSeeds[i]) list.push(fullNameConverter[comp]);
        }
        const data = new FormData();
        data.set('action', 'wipeScrutineering');
        data.set('list', JSON.stringify(list));
        Axios.post(apiString, data).then(() => {
            setConfirmSeedAction('');
            setShowSeedModal(false);
            loadData();
        });
    }

    const seedScrutineering = () => {
        setLoading(true);
        const list = [];
        for (let i = 0; i < Object.keys(compConverter).length; i++) {
            const comp = Object.keys(compConverter)[i];
            if (checkedSeeds[i]) list.push(fullNameConverter[comp]);
        }
        const data = new FormData();
        data.set('action', 'wipeScrutineering');
        data.set('list', JSON.stringify(list));
        Axios.post(apiString, data).then(() => {
            setConfirmSeedAction('');
            setShowSeedModal(false);
            reseed(loadData, ogData.dancers, ogData.ageGroups, ogData.dataMatrix, checkedinList, list);
        });
    }

    const handleConfirmedSeedAction = () => {
        setSelectedCompetition('');
        setSelectedBucket('');
        setSelectedSubbucket('');
        setSelectedDance('');
        if (confirmSeedAction === 'wipe') wipeScrutineering();
        else seedScrutineering();
    }

    return (
        <>
            <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
                {/* <p>Reseeding wipes out all scrutineering tables <strong style={{color: 'red'}}>(so be careful)</strong>, and then replaces them with new, empty up-to-date tables.</p> */}

                {/* <Button primary onClick={() => setReseedConfim(true)}>Reseed (old way - will be getting deleted soon)</Button> */}
                {/* <div style={{width: '100%', height: 20}}></div> */}
                <Button style={{marginTop: 16}} primary onClick={openSeedModal}>Seed Options</Button>
                <div style={{width: '100%', height: 20}}></div>
                <Button primary onClick={() => crunchResults(masterMatrix, loadData, setLoading, scrutineeringTables)}>Crunch Results</Button>
                <div style={{width: '100%', height: 20}}></div>
                <Button primary onClick={handlePublishClick}>Publish Options</Button>



                <Form style={{width: 250, margin: '100px auto 0 auto'}}>
                    <Form.Field control={Select} value={selectedCompetition} options={getCompetitionList()} label="Competition" fluid onChange={changeSelectedCompetition} />

                    {/*Select Dance If No Bucket Items*/}
                    {selectedCompetition !== '' && masterMatrix[selectedCompetition].bucketType === '' &&
                        <Form.Field control={Select} value={selectedDance} options={getDanceList()} label="Dance" fluid onChange={changeSelectedDance} />
                    }

                    {/*Select Bucket item*/}
                    {(selectedCompetition !== '' && masterMatrix[selectedCompetition].bucketType !== '') &&
                        <Form.Field control={Select} value={selectedBucket} options={getBucketList()} label={masterMatrix[selectedCompetition].bucketType} fluid onChange={changeSelectedBucket} />
                    }

                    {/*Select Dance If No Subbucket Items*/}
                    {selectedBucket !== '' && masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketType === '' &&
                        <Form.Field control={Select} value={selectedDance} options={getDanceList()} label="Dance" fluid onChange={changeSelectedDance} />
                    }
                    
                    {/*Select Subbucket Item*/}
                    {(selectedBucket !== '' && masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketType !== '') &&
                        <Form.Field control={Select} value={selectedSubbucket} options={getSubbucketList()} label={masterMatrix[selectedCompetition].bucketItems[selectedBucket].subbucketType} fluid onChange={changeSelectedSubbucket} />
                    }

                    {/*Select Dance */}
                    {selectedSubbucket !== '' &&
                        <Form.Field control={Select} value={selectedDance} options={getDanceList()} label="Dance" fluid onChange={changeSelectedDance} />
                    }

                    {selectedDance !== '' &&
                        <Button primary onClick={() => setShowTableModal(true)}>Do Work</Button>
                    }
                </Form>

                {reseedConfirm &&
                    <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                        <Header as="h3" textAlign="center">Confirm</Header>
                        <Modal.Content>
                            <p>Are you sure you want to reseed?</p>
                            <p><strong style={{color: 'red'}}>This will wipe out all scrutineering tables.</strong></p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary onClick={() => setReseedConfim(false)}>Cancel</Button>
                            <Button negative onClick={() => reseed(loadData, ogData.dancers, ogData.ageGroups, ogData.dataMatrix, checkedinList)}>Yes</Button>
                        </Modal.Actions>
                    </Modal> 
                }

                {showTableModal && <ScrutineerTableModal ogList={getTableData()} competition={selectedCompetition} bucket={selectedBucket} subbucket={selectedSubbucket} dance={selectedDance} handleDoneClick={handleDoneClick} judgeCount={masterMatrix[selectedCompetition].judgeCount} />}
            </div>

            {showPublishModal &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Publish Options</Header>
                    <Modal.Content style={{textAlign: 'center'}}>
                        {Object.keys(publishOptions).map((comp, index) => {
                            return <React.Fragment key={index}>
                                <Button style={{width: 250}} color={publishOptions[comp] ? 'red' : 'blue'} onClick={() => handlePublishChange(comp)}>{compConverter[comp]}: {publishOptions[comp] ? 'Unpublish' : 'Publish'}</Button>
                                <div style={{width: '100%', height: 10}}></div>
                            </React.Fragment>
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setShowPublishModal(false)}>Done</Button>
                    </Modal.Actions>
                </Modal> 
            }
            {showSeedModal &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Seed Options</Header>
                    <Modal.Content style={{textAlign: 'center'}}>
                        <Form style={{width: 250, margin: '0 auto', textAlign: 'left'}}>
                            {Object.keys(compConverter).map((comp, index) => {
                                return <Form.Field style={{marginLeft: 20}} key={index} control={Checkbox} label={compConverter[comp]} checked={checkedSeeds[index]} onChange={() => changeSeedOption(index)} />
                            })}
                        </Form>

                        <div style={{width: '70%', margin: '40px auto 0 auto', height: 20}}>
                            <p style={{cursor: (checkedSeeds.includes(true) ? 'pointer' : 'default'), fontWeight: 'bold', fontSize: 16, float: 'left', color: (checkedSeeds.includes(true) ? '#0494e1' : '#888')}} onClick={() => checkedSeeds.includes(true) ? setConfirmSeedAction('clear') : null}>Reseed</p>
                            <p style={{cursor: (checkedSeeds.includes(true) ? 'pointer' : 'default'), fontWeight: 'bold', fontSize: 16, float: 'right', color: (checkedSeeds.includes(true) ? '#0494e1' : '#888')}} onClick={() => checkedSeeds.includes(true) ? setConfirmSeedAction('wipe') : null}>Wipe</p>
                        </div>

                        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setShowSeedModal(false)}>Done</Button>
                    </Modal.Actions>
                </Modal>
            }

            {confirmSeedAction !== '' &&
                    <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                        <Header as="h3" textAlign="center">Confirm</Header>
                        <Modal.Content>
                            <p>Are you sure you want to proceed?</p>
                            <p><strong style={{color: 'red'}}>This will delete any recorded data.</strong></p>

                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary onClick={() => setConfirmSeedAction('')}>Cancel</Button>
                            <Button negative onClick={handleConfirmedSeedAction}>Yes</Button>
                        </Modal.Actions>
                    </Modal> 
                }
        </>
        
    );
}

export default Scrutineering;