
import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Header, TextArea, Select, Checkbox,  } from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Axios from 'axios';
import apiString from '../components/apiString';

let selectionList = [];
selectionList.push({key: '', text: '', value: '', disabled: true});
for (let i = 0; i < 49; i++) {
    selectionList.push({key: i+1, text: i+1, value: i+1});
}

const AgeGroupModal = ({setAgeGroupModal, ageGroupInfo, setLoading, ageGroupId, danceId, pullAgeGroupData, classification}) => {
    const [ageGroup, setAgeGroup] = useState(JSON.parse(JSON.stringify(ageGroupInfo)));
    const [startingNumberList, setStartingNumberList] = useState([]);
    const [underNumberList, setUnderNumberList] = useState([]);

    const checkDisabled = () => {
        return (ageGroup.startingNumber === '' || ageGroup.underNumber === '');
    }

    const handleDoneClick = () => {
        setLoading(true);
        const formData = new FormData();
        formData.set('action', 'updateAgeGroupInfo');
        formData.set('ageGroupId', ageGroupId);
        formData.set('danceId', danceId);
        formData.set('classification', classification);
        formData.set('startingNumber', ageGroup.startingNumber);
        formData.set('underNumber', ageGroup.underNumber);
        Axios.post(apiString, formData).then(() => {
            setLoading(false);
            pullAgeGroupData(danceId, classification);
            setAgeGroupModal('');
        });
    }

    const handleFormChange = (e, {id, value}) => {
        setAgeGroup({...ageGroup, [id]: value});
    }

    return (
        <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
            <Header as="h3" textAlign="center">Age Group Info</Header>
            <Modal.Content>
                <Form style={{width: 250, margin: '0 auto'}}>
                    <Form.Field id="startingNumber" control={Select} value={ageGroup.startingNumber} options={selectionList} fluid onChange={handleFormChange} />
                    <p style={{textAlign: 'center'}}>And Under</p>
                    <Form.Field id="underNumber" control={Select} value={ageGroup.underNumber} options={selectionList} fluid onChange={handleFormChange} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setAgeGroupModal('')}>Cancel</Button>
                <Button primary disabled={checkDisabled()} onClick={handleDoneClick}>Done</Button>
            </Modal.Actions>
        </Modal> 
    );
}

export default AgeGroupModal;