
import { useEffect, useState } from 'react';
import { Button, Checkbox, Table, Icon, Popup, Modal, Header } from 'semantic-ui-react';
import { capitalizeWord, eventDate1, eventDate2 } from './utilities';
import moment from 'moment';
import _ from 'lodash';
import Axios from 'axios';
import apiString from '../components/apiString';
import EditDancerModal from './editDancerModal';

const checkedInToCompetitionConverter = {
    checkedinopen: 'Open Championship',
    checkedinjig: 'Irish Jig Challenge - 5 Steps',
    checkedinprechamp: 'Pre-Championship',
    checkedinprecomp: 'Pre-Premier Competition',
    checkedinscottish: 'Scottish National Dance Premiership'
}

const tableHeaders = ['Date', '#', 'Age', 'Name', 'Class', 'Teacher', 'Details'];
const headerToSortConverter = {
    Date: 'timestamp',
    Age: 'age',
    Name: 'name',
    Class: 'classification',
    Teacher: 'teacher',
    dances: 'dances',
    '#': 'dancer #',
    Details: 'edit',
    'Checked In': 'checkedin'
};

const competitionConverter = {
    'Open Championship': 'Open',
    'Irish Jig Challenge - 5 Steps': 'Jig',
    'Scottish National Dance Premiership': 'Nationals',
    'Pre-Premier Competition': 'Pre-Premier',
    'Pre-Championship': 'Pre-Champ',
};

const checkedInConverter = {
    checkedinopen: 'Open',
    checkedinjig: 'Jig',
    checkedinprechamp: 'Pre-Champ',
    checkedinprecomp: 'Pre-Premier',
    checkedinscottish: 'Nationals'
};

const DancerTable = ({ogDancers, setLoading, ogMasterDanceList, searchString, pullDancers, updateRootData, page, checkedInSelect}) => {
    const [dancers, setDancers] = useState([]);
    const [sortColumn, setSortColumn] = useState('Date');
    const [sortDirection, setSortDirection] = useState('ascending');
    const [dancerModal, setDancerModal] = useState('');
    const [masterDanceList, setMasterDanceList] = useState([]);
    const [undoCheckinConfirm, setUndoCheckinConfirm] = useState('');

    useEffect(() => {
            const list = ogDancers.map(dancer => {
                const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
                dancer.age1 = eventDate1.diff(dob, 'years');
                dancer.age2 = eventDate2.diff(dob, 'years');
                dancer.age = dancer.age1 === dancer.age2 ? dancer.age1 : dancer.age1 + '/' + dancer.age2;
                dancer.name = dancer.firstname + ' ' + dancer.lastname;
                dancer.teacher = dancer.teacherlastname;
                dancer.highlight = +dancer.highlight;
                dancer['dancer #'] = dancer.dancenumber;
                return dancer;
            });
            const direction = sortDirection === 'ascending' ? 'asc' : 'desc';
            const sortedDancers = _.orderBy(list, [headerToSortConverter[sortColumn]], [direction]);
            setDancers(sortedDancers);
            setMasterDanceList(ogMasterDanceList);
            setLoading(false);
            setUndoCheckinConfirm('');
    }, [ogDancers]);

    const handleHeaderClick = header => {
        let newDirection = 'asc';
        if (sortColumn === header) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
            newDirection = sortDirection === 'ascending' ? 'desc' : 'asc';
        }else {
            setSortColumn(header);
            setSortDirection('ascending');
        }
        const sortedDancers = _.orderBy(dancers, [headerToSortConverter[header]], [newDirection]);
        setDancers(sortedDancers);
    }

    const handleCloseModal = () => {
        setDancerModal('');
        pullDancers(searchString, true);
    }

    const handleCheckboxClick = (index, comp) => {
        if (+dancers[index][comp] === 1) {
            setUndoCheckinConfirm(index + ',' + comp);
        }else {
            setLoading(true);
            const data = new FormData();
            data.set('action', 'checkinDancer');
            data.set('dancerId', dancers[index].id);
            data.set('competition', comp);
            Axios.post(apiString, data).then(() => {
                pullDancers();
            });
        }
    }

    const handleUnchecked = () => {
        const deets = undoCheckinConfirm.split(',');
        setLoading(true);
        const data = new FormData();
        data.set('action', 'undoCheckinDancer');
        data.set('dancerId', dancers[deets[0]].id);
        data.set('competition', deets[1]);
        Axios.post(apiString, data).then(() => {
            pullDancers();
        });
    }

    const prepDanceList = dances => {
        const list = dances.split(',');
        for (let i = 0; i < list.length; i++) {
            list[i] = competitionConverter[list[i]];
        }
        return list.join(' // ');
    }

    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <div style={{position: 'relative'}}>
                <p style={{margin: 0, padding: 0, fontSize: 12, position: 'absolute', top: -20, left: ((window.innerWidth - 1082)/2)}}>{dancers.length} Dancers</p>
                <Table style={{width: 1000, margin: '10px auto', textAlign: 'center'}} size="small" compact celled sortable selectable>
                    <Table.Header>
                        <Table.Row>
                            {tableHeaders.map((header, index) => {
                                return <Table.HeaderCell key={index} sorted={sortColumn === header ? sortDirection : null} onClick={() => handleHeaderClick(header)}>{capitalizeWord(header)}</Table.HeaderCell>
                            })}
                            {checkedInSelect[0] === 'None' && <Table.HeaderCell>Competitions</Table.HeaderCell>}
                            {checkedInSelect[0] !== 'None' &&
                                checkedInSelect.map((comp, index) => {
                                    return <Table.HeaderCell key={index}>{checkedInConverter[comp]}</Table.HeaderCell>
                                })
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dancers.map((dancer, index) => {
                            return (
                                <Popup key={dancer.name} content={dancer.notes} disabled={dancer.notes === '' || dancer.notes === null} trigger={
                                    <Table.Row style={{cursor: 'default', backgroundColor: (+dancer.highlight === 1 ? '#ffff66' : +dancer.checkedin === 1 ? '#a9d797' : '')}}>
                                        <Table.Cell>{moment.unix(dancer.timestamp).format('MM/DD/YYYY')}</Table.Cell>
                                        <Table.Cell>{dancer['dancer #']}</Table.Cell>
                                        <Table.Cell>{dancer.age}</Table.Cell>
                                        <Table.Cell>{dancer.name}</Table.Cell>
                                        <Table.Cell>{dancer.classification}</Table.Cell>
                                        <Table.Cell>{dancer.teacher}</Table.Cell>
                                        <Table.Cell><Icon style={{cursor: 'pointer'}} name="magnify" color="blue" onClick={() => setDancerModal(index)}/></Table.Cell>
                                        {checkedInSelect[0] === 'None' && <Table.Cell>{prepDanceList(dancer.dances)}</Table.Cell>}
                                        {checkedInSelect[0] !== 'None' &&
                                            checkedInSelect.map((comp, index2) => {
                                                return (
                                                    <>
                                                        {dancer.dances.includes(checkedInToCompetitionConverter[comp]) &&
                                                            <Table.Cell key={index2}>{<Checkbox checked={+dancer[comp] === 1} onClick={() => handleCheckboxClick(index, comp)} />}</Table.Cell>
                                                        }
                                                        {!dancer.dances.includes(checkedInToCompetitionConverter[comp]) &&
                                                            <Table.Cell key={index2}></Table.Cell>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </Table.Row>
                                } />
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>

            {undoCheckinConfirm !== '' &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Confirm</Header>
                    <Modal.Content>
                        <p>Are you sure you want undo checkin?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setUndoCheckinConfirm('')}>Cancel</Button>
                        <Button negative onClick={handleUnchecked}>Yes</Button>
                    </Modal.Actions>
                </Modal> 
            }
       
            {dancerModal !== '' && <EditDancerModal info={dancers[dancerModal]} setDancerModal={setDancerModal} masterDanceList={masterDanceList} setLoading={setLoading} handleCloseModal={handleCloseModal} />}
        </div>
    );
}

export default DancerTable;



















