import Axios from 'axios';
import apiString from '../components/apiString';
import { useEffect, useState } from 'react';
import { Input, Button, Form } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { eventDate1, eventDate2 } from '../components/utilities';
import DancerTable from '../components/dancerTable';

const Dancers = ({setLoading}) => {
    const [searchString, setSearchString] = useState('');
    const [dancers, setDancers] = useState([]);
    const [masterDanceList, setMasterDanceList] = useState([]);
    const [startingDanceNumber, setStartingDanceNumber] = useState(0);

    useEffect(() => {
        setLoading(true);
        pullDancers('');
    }, []);

    const pullDancers = _.debounce((search) => {
        let pulls = [];
        const formdata = new FormData();
        formdata.set('action', 'getDancerList');
        formdata.set('searchString', search);
        pulls.push(Axios.post(apiString, formdata));
        const pullDances = new FormData();
		pullDances.set('action', 'getDanceList');
		pulls.push(Axios.post(apiString, pullDances));
        const pullStartingDanceNumber = new FormData();
        pullStartingDanceNumber.set('action', 'getStartingDanceNumber');
        pulls.push(Axios.post(apiString, pullStartingDanceNumber));
        Axios.all(pulls).then(response => {
            const list = response[0].data.map(dancer => {
                const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
                dancer.age1 = eventDate1.diff(dob, 'years');
                dancer.age2 = eventDate2.diff(dob, 'years');
                dancer.age = dancer.age1 === dancer.age2 ? dancer.age1 : dancer.age1 + '/' + dancer.age2;
                dancer.name = dancer.firstname + ' ' + dancer.lastname;
                dancer.teacher = dancer.teacherlastname;
                dancer.highlight = +dancer.highlight;
                return dancer;
            });
			setMasterDanceList(response[1].data);
            setDancers(list);
            setStartingDanceNumber(response[2].data);
            setLoading(false);
        });
    }, 300);

    const changeSearchTerm = e => {
        setSearchString(e.target.value);
        pullDancers(e.target.value);
    }

    const updateStartingNumber = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'updateStartingDanceNumber');
        data.set('startingDanceNumber', startingDanceNumber);
        Axios.post(apiString, data).then(() => {
            pullDancers(searchString);
        });
    }

    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>

            {/* <p style={{color: 'red'}}>INTERNAL NOTE FOR KR:  in the backend code in the action "getDataForLineupSheets": dont forget to uncomment the "checked in" logic in the SQL query in both the public site and this admin site - currently line 173 in internalapi.php</p> */}
            {/* <p style={{color: 'red'}}>scrutineering reseed "checked in" check should say !== 1, double check it before pushing live - currently line 131 in scrutineeringLogic.js</p> */}
            {/* <p style={{color: 'red'}}>change scoring</p> */}
            <p style={{color: 'red'}}> make sure in whatever code I have to clear out the scrutineering table (the numbers, not the rows) that we clear out all the new columns as well</p>
            <Input icon="search" style={{height: 40, width: 300}} value={searchString} onChange={changeSearchTerm} />
            <div style={{width: 250, height: 80, margin: '20px auto 0 auto'}}>
                <Form style={{width: 150, float: 'left'}}>
                    <Form.Field control={Input} value={startingDanceNumber} label="Starting Number" onChange={e => setStartingDanceNumber(e.target.value)} />
                </Form>
                <Button primary style={{float: 'right', marginTop: 24}} onClick={updateStartingNumber}>Apply</Button>
            </div>

            <div style={{position: 'relative'}}>
                <DancerTable setLoading={setLoading} ogDancers={dancers} ogMasterDanceList={masterDanceList} searchString={searchString} pullDancers={pullDancers} checkedInSelect={['None']} />
            </div>
        </div>
    );
}

export default Dancers;